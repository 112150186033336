<template>
<div v-if="perms.includes('Facturation Clients') || perms.includes('Admin')">
   <div v-if="loading">
          <div id="loading-bg">
      <!-- <div class="loading-logo">
        <img src="<%= BASE_URL %>logo.png" alt="Logo" />
      </div> -->
      <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div>
  </div>
  <section class="invoice-add-wrapper" v-else>
    <b-row class="invoice-add">
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-form @submit.prevent id="formdata">
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">

              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <!-- <logo /> -->
                    <h4 class="text-primary invoice-logo">
                       Facture Client N° : {{Client.NumClient}}
                    </h4>
                  </div>
                  <b-card-text class="mb-25">
                    <h5>{{Client.NomSociete}}</h5> 
                  </b-card-text>
                  <b-card-text class="mb-25">
                    <b>ICE :</b> {{Client.Ice}}
                  </b-card-text>
                  <b-card-text class="mb-0">
                    <b>Tél / Adresse :</b> {{Client.phone}} / {{Client.adresse}}
                  </b-card-text>
                </div>

                <!-- Header: Right Content -->
                <!-- <div class="invoice-number-date mt-md-0 mt-2">
                  <div class="d-flex align-items-left justify-content-md-end mb-1">
                    <h4 class="invoice-title">
                       N° Facture
                    </h4>
                      <b-form-input
                       :value="rows.NumFact"
                       disabled                      
                      />
                  </div>
                  <div class="d-flex align-items-right justify-content-md-end mb-1">
                    <h4 class="invoice-title">
                      Date Le:
                    </h4>
                    <b-form-input
                       :value="getDate(rows.DateFcat)"
                       disabled                      
                      />
                  </div>
              
                </div> -->
                <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  FACTURE N° :
                  <span class="invoice-number"> {{ rows.NumFactClient }}</span>
                </h4>
                <div class="invoice-date-wrapper" style=" margin-top: 25px;">
                  <p class="invoice-title">
                    AGADIR LE :
                  </p>
                  <!-- <p class="invoice-date">
                    {{ getDate(rows.DateDevis) }}
                  </p> -->
                  <p class="invoice-date" v-if="rows.DateFact">
                    {{getDate(rows.DateFact) }}
                  </p>
                </div>
              </div>
              </div>
            </b-card-body>
            <div style="max-height: 600px; overflow: auto;">
            <b-card style="padding: 0rem;" v-for="(bl, index) in ListBL" :key="index" :title="'Bon Livraison N° :'+bl.NumBL" class="invoice-padding form-item-section">
                <div ref="form">
                  
                    <b-row
                      v-for="(item, index) in bl.Products"
                      :key="index"
                      ref="row"
                      class="pb-2"
                    >
                      <b-col cols="12">

                        <div class="d-none d-lg-flex">
                          <b-row class="flex-grow-1 px-1">
                            <!-- Single Item Form Headers -->
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              Produit
                            </b-col>
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              Prix Vente
                            </b-col>
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              TVA
                            </b-col>
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              Quantité
                            </b-col>
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              Total H.T
                            </b-col>
                             <b-col
                              cols="12"
                              lg="2"
                            >
                              Total T.T.C
                            </b-col>
                          </b-row>
                          <div class="form-item-action-col" />
                        </div>

                        <div class="d-flex border rounded">
                          <b-row class="flex-grow-1 p-2">
                             <b-col
                              cols="12"
                              lg="2"
                            >
                              <label class="d-inline d-lg-none">Produit</label>
                              <b-form-input
                                type="text"
                                class="mb-2"
                                :value="item.Nom"
                                disabled
                              />
                            </b-col>
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              <label class="d-inline d-lg-none">Prix Vente</label>
                              <b-form-input
                                type="number"
                                class="mb-2"
                                :value="item.Prix_Vente"
                                disabled
                              />
                            </b-col>
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              <label class="d-inline d-lg-none">TVA</label>
                              <b-form-input
                                type="number"
                                class="mb-2"
                                disabled
                                :value="item.tva"
                              />
                            </b-col> 
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              <label class="d-inline d-lg-none">Quantité</label>
                              <b-form-input
                                type="number"
                                class="mb-2"
                                v-model="item.qnt"
                                :name="'qnty'+index"
                                 disabled
                                
                                />
                               
                            </b-col>
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              <label class="d-inline d-lg-none">Total H.T</label>
                              <b-form-input
                                type="number"
                                class="mb-2"
                                disabled
                                :value="item.tot"
                              />
                            </b-col>
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              <label class="d-inline d-lg-none">Total T.T.C </label>
                              <b-form-input
                                type="number"
                                class="mb-2"
                                disabled
                                :value="TwoDigits((item.Prix_Vente * item.qnt)*(1+(item.tva/100)))"
                              />
                            </b-col>
                           
                          </b-row>
                          <!-- <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                            <feather-icon
                              size="16"
                              icon="XIcon"
                              class="cursor-pointer"
                              @click="removeItemProduct(rows.Products,index)"
                            />
                           
                          </div> -->
                        </div>
                      </b-col>
                    </b-row>
                </div>
             </b-card>
            </div>


              <hr class="my-50">
            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>

               
                    <b-col 
                      cols="12"
                      md="8"
                      class="mt-md-6 "
                      order="1"
                      order-md="2"
                    >
                  <div class="invoice-total-wrapper" style="max-width: 15rem;">
                  
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Facture Payée  :
                      </p>
                      <p class="invoice-total-amount" v-if="rows.paid==1">
                      OUI
                      </p>
                      <p class="invoice-total-amount" v-if="rows.paid==0">
                      Non
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Fournis au Client  :
                      </p>
                      <p class="invoice-total-amount" v-if="rows.livred==1">
                      Oui
                      </p>
                      <p class="invoice-total-amount" v-if="rows.livred==0">
                      Non
                      </p>
                    </div>
                    <div class="invoice-total-item" v-if="rows.reglement !==''">
                      <p class="invoice-total-title">
                        Réglement :
                      </p>
                      <p class="invoice-total-amount">
                        {{rows.reglement}} 
                      </p>
                    </div>
                    <div class="invoice-total-item" v-if="rows.reglement !==''">
                      <p class="invoice-total-title">
                        Numéro Chèque :
                      </p>
                      <p class="invoice-total-amount">
                        {{rows.NumCheq}}
                      </p>
                    </div>
                    <div class="invoice-total-item" v-if="rows.reglement !==''">
                      <p class="invoice-total-title">
                        BQ :
                      </p>
                      <p class="invoice-total-amount">
                        {{rows.BQ}}
                      </p>
                    </div>
                    <div class="invoice-total-item" v-if="rows.reglement !==''">
                      <p class="invoice-total-title">
                        La banque :
                      </p>
                      <p class="invoice-total-amount">
                        {{rows.banque}}
                      </p>
                    </div>
                  </div>
                    </b-col>
                  
                  
               
                 <b-col
                  cols="12"
                  md="4"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper" style="max-width: 18rem;">
                    
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total H.T:
                      </p>
                      <p class="invoice-total-amount">
                      {{rows.THT}} DHs
                      </p>
                    </div>
                     <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total TVA:
                      </p>
                      <p class="invoice-total-amount">
                     {{TwoDigits(rows.TTV)}}
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total T.T.C:
                      </p>
                      <p class="invoice-total-amount">
                      {{TwoDigits(rows.TTC)}} DHs
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Remise:
                      </p>
                      <p class="invoice-total-amount">
                        {{rows.remise}} %
                      </p>
                    </div>
                   
                    <hr class="my-50">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total NET A PAYER:
                      </p>
                      <p class="invoice-total-amount">
                       {{TwoDigits(rows.TotNet)}} Dhs
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
            <!-- Note -->
            
             <hr class="my-50">
            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>

                <b-card-body class="invoice-padding pt-0">
                   <b-col 
                cols="12"
                md="12"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper" style="max-width: 100rem;">
                 
                
                      
                    <label for="textarea-no-auto-shrink">NOTE :</label>
                <b-form-textarea
                    rows="2"
                    max-rows="8"
                    no-auto-shrink
                    :value="rows.notefact"
                    disabled
                   
                />
                
                  
                 
                </div>
              </b-col>
                </b-card-body>

              </b-row>
            </b-card-body>
          </b-card>
        </b-form>
      </b-col>
 
      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2" >
          <b-card>
            <h3>Facture à télecharger</h3><br/>
            <div class="d-flex justify-content-between align-items-center">
            <label for="nHnP">Sans En-tête & Pièces Jointes</label>
           
            <b-form-radio
              id="nHnP"
              v-model="selected"
              value="nHnP"
              switch
              selected
            />
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <label for="HP">En-tête & Pièces Jointes</label>
          
            <b-form-radio
              id="HP"
              v-model="selected"
              value="HP"
              unchecked-value="0"
              switch
            />
          </div>

          <!-- Client Notes -->
          <div class="d-flex justify-content-between align-items-center my-1">
            <label for="HnP">Juste En-tête </label>
            
            <b-form-radio
              id="HnP"
              v-model="selected"
              value="HnP"
              unchecked-value="0"
              switch
            />
          </div>

          <!-- Payment Stub -->
          <div class="d-flex justify-content-between align-items-center my-1">
            <label for="nHP">Juste Pièces Jointes</label>
          
            <b-form-radio
              id="nHP"
              v-model="selected"
              value="nHP"
              unchecked-value="0"
              switch
            />
          </div>

          <br/><br/>
         
        
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            title="CTRL+P"
            @click="FactPDF(rows.NumFact)"
          >
            Télécharger PDF
          </b-button>

          <!-- Button: Print -->
          <!-- <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Imprimer
          </b-button> -->
     
        </b-card>
      

       
      </b-col>
    </b-row>
  </section>
  </div>
  <div v-else>
  <not-authoriz></not-authoriz>
</div>
</template>

<script>
import config from '@/config'
import moment from 'moment';
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import router from '@/router'
import {
  BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, 
  BInputGroupPrepend, BFormTextarea, BFormCheckbox, BPopover, VBToggle,BTableLite,
  BMedia, BMediaAside, BMediaBody, BLink,BImg,BFormRadio
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import NotAuthoriz from '@/views/admin/NotAuthoriz.vue'

// import invoiceStoreModule from './invoiceStoreModule'
// import InvoiceSidebarAddNewCustomer from '../InvoiceSidebarAddNewCustomer.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,BTableLite,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BMedia, BMediaAside, BMediaBody, BLink,BImg,
    flatPickr,
    vSelect,
    Logo,
    BFormRadio,
    NotAuthoriz
    // InvoiceSidebarAddNewCustomer,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  mixins: [heightTransition],
  data(){
      return{
        perms:[],
        loading:false,
          Client:[],
          Produits:[],
          rows:{},
          ListBL:[],
          NumDevis:'',
          totalHT:0,
          totalNET:0,
          tva:0,
          DateDevis:'',
          remise:0,
          TTC:0,
          prod:[],
          newProdc:[],
          HP:1,
          nHnP:1,
          HnP:1,
          nHP:1,
          selected:'nHnP',
      }
  },
  mounted() {
    
    this.perms=JSON.parse(localStorage.getItem('access'));
      // this.getProduct();
    this.loading = true
    this.$http.get(`${config.API_BASE_URL}/api/auth/getProductFact/${router.currentRoute.params.id}`).then(res => { 
      this.rows = res.data.Fact,
      this.ListBL=res.data.BLS,
      this.loading = false
      });

      this.$http.get(`${config.API_BASE_URL}/api/auth/ShowClient/${router.currentRoute.params.clientId}`).then(res =>{
        if(res.data.message=='success'){
          this.Client=res.data.client;
        }else{

        }
      });
    // 
    this.$mousetrap.bind('ctrl+p', this.logIt);
 
 },
  
  
 
 
  methods: {
    logIt(e) {
        // console.log('hello raccorcie');
        this.$http.get(`${config.API_BASE_URL}/api/auth/getFactClientPDF/${router.currentRoute.params.id}/nHnP`,{responseType: 'arraybuffer'})
                .then(response => { 
                  //  console.log(response.data)
                    let blob = new Blob([response.data], { type: 'application/pdf' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'Facture.pdf'
                    link.click()
                })
        return false;
      },
      TwoDigits(val){
            return parseFloat(val).toFixed(2);
      },

    getDate(value){
      return  moment(String(value)).format('DD/MM/YYYY');
    },
  
      getProduct(){
          this.$http.get(`${config.API_BASE_URL}/api/auth/getProducts`)
      .then(res => { this.Produits = res.data })
      },
      FactPDF(num){
          if(this.selected == false ){
            this.$swal.fire({
                text: "Merci de choisir le type de facture à télecharger !!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: `D'accord`
                })
          }else{
              // let data= new FormData;
              // data.append('selected', this.selected);
              // console.log(Object.fromEntries(data))
              this.$http.get(`${config.API_BASE_URL}/api/auth/getFactClientPDF/${router.currentRoute.params.id}/${this.selected}`,{responseType: 'arraybuffer'})
                .then(response => { 
                  //  console.log(response.data)
                    let blob = new Blob([response.data], { type: 'application/pdf' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'Facture_N'+num+'.pdf'
                    link.click()
                })
          }
         
        
      }
      
    
  },

  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    // if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })


    const itemFormBlankItem = {
      id:0,
      Nom: null,
      Prix_Vente: 0,
      qnt: 0,
      tva:0,
      tot:0,
    }
   
    const FactureData= ref({
        produits :[JSON.parse(JSON.stringify(itemFormBlankItem))],
    })
    const invoiceData = ref({
      id: 5037,
      client: null,

      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],
    })

    
     const updateItemForm = (index, val) => {
      const { Prix_Vente, id, Nom ,tva} = val
      FactureData.value.produits[index].Prix_Vente = Prix_Vente
      FactureData.value.produits[index].id = id
      FactureData.value.produits[index].Nom = Nom
      FactureData.value.produits[index].tva = tva
    //   this.TTC=this.TwoDigits((Prix_Vente * qnt)*(1+(tva/100)))
    //   FactureData.value.produits[index].total = Prix_Achat*quantite
    
    }
   const printInvoice = () => {
      window.print()
    }

    // console.log(this.rows.Products)

    return {
      FactureData,
      invoiceData,
       printInvoice,
      updateItemForm,
      itemFormBlankItem,
    }
  },
}
</script>


<style scoped>
.media-aside.align-self-start {
    display: grid;
} 
.invoice-add .invoice-total-wrapper[data-v-cc5561c2] {
    width: 100%;
    max-width: 18rem;
}
</style>
<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .row.content-header {
    display: none;
}
.invoice-actions.mt-md-0.mt-2.col-md-4.col-xl-3.col-12 {
    display: none;
}
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }
.col-md-8.col-xl-9.col-12 {
    max-width: 100%;
}
.col-xl-9 {
    flex: 0 0 100%;
    max-width: 100%;
}
  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 100% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
